import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, mobileHero } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="95VH"
          maxHeight="680px"
          w="100%"
          image={`url(${mobileHero}) center / cover no-repeat`}
          column
          justifyEnd
          alignCenter
          textCenter
        >
          <CFView mb="58%" pulsate>
            <OrderPickupButton />
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="665px"
          w="100%"
          image={`url(${hero}) top / cover no-repeat`}
          zIndex={90}
          column
          justifyBetween
          alignCenter
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <Header />
          <CFView pulsate mb="8%">
            <OrderPickupButton />
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
