import React from 'react'
import {
  CFSelect,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { orderPickupButton } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => (
        <CFView>
          <MobileScreen>
            <a
              href={`https://order.codefusion.tech/restaurants/ie3RHYpjw3tbCoNxmPwP/locations/hdFRSjEM6jPHMwpwcJ1o`}
            >
              <CFView hover>
                <CFImage
                  src={orderPickupButton}
                  w="85%"
                  maxWidth="300px"
                  alt="About"
                />
              </CFView>
            </a>
          </MobileScreen>
          <DefaultScreen>
            <a
              href={`https://order.codefusion.tech/restaurants/ie3RHYpjw3tbCoNxmPwP/locations/hdFRSjEM6jPHMwpwcJ1o`}
            >
              <CFView hover>
                <CFImage src={orderPickupButton} maxWidth="235px" alt="About" />
              </CFView>
            </a>
          </DefaultScreen>
        </CFView>
      )}
    </CFSelect>
  )
}
